import constants from '../constants';

const initialState = { availableCategories: [], clippedCategories: [], categoryMap: {} };

export default function offerCategoriesReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_AVAILABLE_CATEGORIES:
      return { ...state, availableCategories: action.payload };
    case constants.SET_CLIPPED_CATEGORIES:
      const catMap = {};
      if (action.payload.length > 0) {
        for (const c of action.payload) {
          catMap[c.id] = c.name;
        }
      }

      return { ...state, clippedCategories: action.payload, categoryMap: catMap };
    default:
      return state;
  }
}
