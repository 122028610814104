import constants from '../constants';

const initState = {
  loading: false,
  clubPrograms: null,
  enrolledPrograms: [],
  clippedClubReward: [],
  errorMsgClubs: null,
};

export default function clubProgramsReducer(state = initState, action) {
  const copyArr = [...state.enrolledPrograms];
  const clippedArr = [...state.clippedClubReward];

  switch (action.type) {
    case constants.GET_CLUB_PROGRAMS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case constants.ENROLL_CLUB_PROGRAM_REQUEST:
      return {
        ...state,
        enrolledPrograms: [...state.enrolledPrograms, { [action.programId]: { loading: true } }],
      };

    case constants.ENROLL_CLUB_PROGRAM_SUCCESS:
      copyArr.forEach((program) => {
        if (program[action.enrolledProgramId]) {
          program[action.enrolledProgramId].loading = false;
        }
      });

      return {
        ...state,
      };

    case constants.GET_CLUB_PROGRAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        clubPrograms: action.clubPrograms,
      };

    case constants.GET_CLUB_PROGRAMS_ERROR:
      return {
        ...state,
        loading: false,
        errorMsgClubs: action.error,
      };

    case constants.ENROLL_CLUB_PROGRAM_ERROR:
      copyArr.forEach((program) => {
        if (program[action.programId]) {
          program[action.programId].loading = false;
          program[action.programId].error = action.error || 'Something went wrong!';
        }
      });

      return {
        ...state,
      };

    case constants.CLIP_CLUB_REWARD_OFFER_REQUEST:
      return {
        ...state,
        clippedClubReward: [
          ...state.clippedClubReward,
          { id: action.offerRewardId, loading: true, clipped: false },
        ],
      };

    case constants.CLIP_CLUB_REWARD_OFFER_REQUEST_SUCCESS:
      const clipRewardOfferIndex = clippedArr.findIndex(
        (clippedClubReward) => clippedClubReward.id === action.clippedRewardId
      );

      const aux = [...clippedArr];
      const changeData = { ...aux[clipRewardOfferIndex] };
      changeData.loading = false;
      changeData.clipped = true;
      aux[clipRewardOfferIndex] = changeData;

      return {
        ...state,
        clippedClubReward: [...aux],
      };
    case constants.CLIP_CLUB_REWARD_OFFER_REQUEST_ERROR:
      const clipRewardOfferIndexError = clippedArr.findIndex(
        (clippedClubReward) => clippedClubReward.id === action.clippedRewardId
      );

      const errorState = [...clippedArr];
      const errorData = { ...errorState[clipRewardOfferIndexError] };
      errorData.loading = false;
      errorData.error = action.error;
      errorState[clipRewardOfferIndexError] = errorData;

      return {
        ...state,
        clippedClubReward: [...errorState],
      };

    case constants.CLEAR_CLIP_REWARD_OFFER:
      return {
        ...state,
        clippedClubReward: [],
      };
    case constants.CLEAR_CLUB_PROGRAMS:
    default:
      return state;
  }
}
