import constants from '../constants';

const initialState = {
  totalOffers: 0,
  offers: [],
  isLoading: false,
  fetchMore: true,
  totalPages: 1,
};

export default function walletOffersReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_WALLET_OFFERS:
      const { offers, totalOffers } = action.payload;

      return { ...state, offers: [...state.offers, ...offers], totalOffers };
    case constants.SET_WALLET_LOADING_OFFERS:
      return { ...state, isLoading: action.payload };
    case constants.SET_FETCH_MORE_WALLET:
      return { ...state, fetchMore: action.payload };
    case constants.SET_WALLET_TOTAL_PAGES:
      return { ...state, totalPages: action.payload };
    case constants.CLEAR_WALLET_OFFERS:
      return { ...state, offers: [], totalOffers: 0, totalPages: 1 };
    default:
      return state;
  }
}
