import constants from '../constants';

export default function walletReducer(wallet = [], action) {
  switch (action.type) {
    case constants.GET_WALLET:
      return action.data;
      break;
    case constants.CLIP_OFFER:
      return action.data;
      break;
    case constants.CLIP_ALL_OFFER:
      return action.data;
      break;
    case constants.WALLET_CLEAR:
      return action.data;
      break;
    default:
      return wallet;
  }
}
