import constants from '../constants';

export default function accountTypeReducer(accountType = [], action) {
  switch (action.type) {
    case constants.ACCOUNT_TYPE_GET:
      return action.data;
      break;
    default:
      return accountType;
  }
}
