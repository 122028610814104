import constants from '../constants';

const initialState = { status: '', errorCode: '', redemptionTypeList: [] };

export default function cashBackReducer(state = initialState, { type, payload }) {
  switch (type) {
    case constants.CASHBACK_GET:
      return payload;
      break;
    case constants.CASHBACK_BALANCE_GET:
      return {
        ...state,
        status: 'balance found',
        redemptionTypeList: payload.redemptionTypeList,
      };
      break;
    case constants.CASHBACK_PAYMENT:
      return {
        ...state,
        status: payload.status,
        errorCode: payload.errorCode,
        redemptionTypeList: payload.redemptionTypeList,
      };
      break;
    case constants.CASHBACK_CLEAR:
      return initialState;
      break;
    default:
      return state;
  }
}
