import constants from '../constants';

export default function offerReducer(offer = [], action) {
  switch (action.type) {
    case constants.GET_ALL_OFFER_DATA:
      return action.data;
      break;
    case constants.GET_OFFER_DATA:
      return action.data;
      break;
    case constants.GET_KEYWORD_OFFER_DATA:
      return action.data;
      break;
    case constants.VIEW_BY_OFFER_DATA:
      return action.data;
      break;
    case constants.OFFER_CLEAR:
      return action.data;
    default:
      return offer;
  }
}
