import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { PERSISTENT_ENVS } from './util/constants';
import configureStore from './redux/store';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/ErrorBoundary';
import { PagesWrapper } from './components/PagesWrapper';
import './asserts/styles/index.scss';

const options = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] },
  },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: 'a05f1f7b08',
    applicationID: process.env.REACT_APP_NEW_RELIC_ID,
    sa: 1,
    appName: `${process.env.REACT_APP_ENV_NAME}: ShopperSite`,
  },
  loader_config: {
    accountID: '98585',
    trustKey: '3203252',
    agentID: process.env.REACT_APP_NEW_RELIC_ID,
    licenseKey: 'a05f1f7b08',
    applicationID: process.env.REACT_APP_NEW_RELIC_ID,
  },
};

if (PERSISTENT_ENVS.includes(process.env.REACT_APP_ENV_NAME)) {
  // eslint-disable-next-line no-new
  new BrowserAgent(options);
}

const App = React.lazy(() => import('./components/App'));

const initialState = {};

const store = configureStore(initialState);

ReactDOM.render(
  <ErrorBoundary>
    <Suspense
      fallback={
        <div className="form-login layout-form-padding" style={{ marginTop: '20px' }}>
          <div className="form-width-constraint">
            <div className="form-container">
              <div className="form-container-body" style={{ border: 0 }}>
                <div className="form-header" style={{ textAlign: 'center' }} />
                <br />
                <br />
                <div className="loader-splash" style={{ color: 'black' }} />
              </div>
            </div>
          </div>
        </div>
      }
    >
      <Provider store={store}>
        <BrowserRouter>
          <PagesWrapper>
            <App />
          </PagesWrapper>
        </BrowserRouter>
      </Provider>
    </Suspense>
  </ErrorBoundary>,
  document.getElementById('root')
);

reportWebVitals();
