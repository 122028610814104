import constants from '../constants';

const initialState = { data: [], isLoading: false };
export default function landingPageReducer(state = initialState, action) {
  switch (action.type) {
    case constants.LANDINGPAGE_GET:
      return action.data;
      break;
    case constants.LANDINGPAGE_CLEAR:
      return action.data;
      break;
    case constants.CLIP_LANDING_PAGE_OFFER:
      const { data: offer } = action;

      const offers = state.data.map((current_offer) =>
        current_offer.mdid === offer.mdid ? { ...current_offer, clipped: true } : current_offer
      );

      const newState = { ...state, data: offers };
      return newState;
      break;
    case constants.CLIP_ALL_LANDING_PAGE_OFFERS:
      const { data: offersNotClipped } = action;

      const newOffers = state.data.map((current_offer) =>
        offersNotClipped.indexOf(current_offer) !== -1
          ? { ...current_offer, clipped: true }
          : current_offer
      );
      const newStat = { ...state, data: newOffers };
      return newStat;
      break;
    case constants.LANDING_PAGE_LOADER:
      return { ...state, isLoading: action.isLoading };
      break;
    default:
      return state;
  }
}
