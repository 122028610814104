import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  alertBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default function ConfigError({ errorType }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert className={classes.alertBox} variant="outlined" severity="error">
        <AlertTitle>{errorType.config ? 'Site can not load' : 'Something went wrong'}</AlertTitle>
        {errorType.config
          ? 'No site exists for that URL - please check what you typed!'
          : 'Please try again later!'}
      </Alert>
    </div>
  );
}
