import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const finalCreateStore = compose(applyMiddleware(thunk, createLogger()))(createStore);

export default function configureStore(initialState = {}) {
  return finalCreateStore(rootReducer, initialState);
}
