import constants from '../constants';

export default function clippedV3Reducer(state = [], action) {
  const clippedStateArr = [...state];
  switch (action.type) {
    case constants.CLIPPED_V3:
      const clipOfferIndex = clippedStateArr.findIndex(
        (clippedData) => clippedData.id === action.payload.id
      );

      if (clipOfferIndex === -1) {
        return [...state, action.payload];
      }
      const aux = [...clippedStateArr];
      const changeData = { ...aux[clipOfferIndex] };
      changeData.isLoading = action.payload.isLoading;
      changeData.success = action.payload.success;
      aux[clipOfferIndex] = changeData;

      return [...aux];

    default:
      return state;
  }
}
