import constants from '../constants';

const initState = {
  loading: false,
  charities: null,
};

export default function charityReducer(state = initState, action) {
  switch (action.type) {
    case constants.GET_CHARITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.GET_CHARITY_SUCCESS:
      return {
        ...state,
        loading: false,
        charities: action.charities,
      };
    case constants.GET_CHARITY_ERROR:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      };
    default:
      return state;
  }
}
