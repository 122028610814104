import constants from '../constants';

export default function rewardReducer(reward = {}, action) {
  switch (action.type) {
    case constants.REWARDS_GET:
      return action.data;
      break;
    case constants.REWARDS_CLIP:
      return action.data;
      break;
    case constants.REWARDS_CLEAR:
      return action.data;
      break;
    default:
      return reward;
  }
}
