import constants from '../constants';

export default function keyWordsReducer(keyWords = [], action) {
  switch (action.type) {
    case constants.GET_KEY_WORDS:
      return action.data;
      break;
    default:
      return keyWords;
  }
}
