import constants from '../constants';

export default function clubWalletReducer(clubWallet = {}, action) {
  switch (action.type) {
    case constants.CLUB_WALLET_GET:
      return action.data;
      break;
    case constants.CLUB_WALLET_CLIP:
      return action.data;
      break;
    case constants.CLUB_WALLET_CLEAR:
      return action.data;
      break;
    default:
      return clubWallet;
  }
}
