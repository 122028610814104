import constants from '../constants';

export default function locationReducer(storeLocation = [], action) {
  switch (action.type) {
    case constants.GET_MAP_LOCATIONS_DATA:
      return action.data;
    default:
      return storeLocation;
  }
}
