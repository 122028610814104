const messages = {
  es: {
    'Month.January': 'Enero',
    'Month.February': 'Febrero',
    'Month.March': 'Marzo',
    'Month.April': 'Abril',
    'Month.May': 'Puede',
    'Month.June': 'Junio',
    'Month.July': 'July',
    'Month.August': 'Julio',
    'Month.September': 'Septiembre',
    'Month.October': 'Octubre',
    'Month.November': 'Noviembre',
    'Month.December': 'Diciembre',

    'BurgerMenu.logoutModalAreYouSure': '¿Estás segura de que quieres cerrar sesión en tu cuenta?',

    'Currency.name': 'Dólar',
    'Currency.symbol': '$',
    'Currency.items': 'elementos',
    'Currency.pointsName': 'puntos',

    'Footer.PrivacyPolicyLocale': 'política de privacidad',
    'Footer.TermsAndConditionsLocale': 'Términos y condiciones',
    'Footer.Release': 'Liberar',
    'Footer.CookieNotice': 'Aviso de cookies',

    'Error.inValidfield': 'Parametro invalido',
    'Error.userEmailCantSend': 'No pudimos enviarte un correo electrónico',
    'Error.userRegisterError':
      'No pudimos completar tu solicitud, Por favor intenta de nuevo. Si el problema persiste, por favor contáctanos en soporte técnico.',
    'Error.userRegisterConflict':
      'No pudimos procesar tu registro, existe un conflicto en tus datos.',
    'Error.userProfileError':
      'No pudimos actualizar tu perfil, por favor revisa por errores e intenta de nuevo.',
    'Error.userUpdatePasswordError':
      'Tenemos problemas para cambiar tu contraseña. Por favor revisa tu contraseña actual.',
    'Error.changePreferStoreError':
      'No pudimos cambiar tu tienda. Por favor revisa e intenta de nuevo.',
    'Error.userLoginError':
      'No pudimos validar tu correo o contraseña, por favor intenta de nuevo.',
    'Error.userForgotPassword':
      'Si ya tienes una cuenta con nosotros, envíaremos un correo a la dirección que nos proporcionaste. Por favor revisa tu correo para ver los pasos siguientes.',
    'Error.userResetPassword': 'No se pudo restablecer el correo electrónico.',
    'Error.userUserData': 'Error al obtener los datos de usuario',
    'Error.userConflict': 'Conflicto',
    'Error.userGeneral': 'Error',
    'Error.IncorrectEmail': 'Usuario o contraseña incorrectos',
    'Error.currentPwdWrong':
      'La contraseña que ingresaste es incorrecta. Por favor intenta de nuevo',
    'Error.fieldValidationPasswordDoNotMatch': 'Las contraseñas no coinciden',
    'Error.fieldValidationCheckTheForm': 'Por favor verifica los valores del formulario',
    'Error.fieldValidationEmptyField': 'No debe haber campos en blanco',
    'Error.fieldValidationFirstNameCharLimit': 'El primer nombre debe ser menor de 255 caracteres',
    'Error.fieldValidationLastNameCharLimit': 'El apellido debe ser menor de 255 caracteres',
    'Error.fieldValidationBirthday':
      'La fecha de nacimiento no puede ser hoy, seleccione el día correcto',
    'Error.fieldValidationBadEmailFormat': 'El formato del correo es incorrecto',
    'Error.fieldValidationPasswordLength': 'La contraseña debe tener al menos 8 caracteres.',
    'Error.fieldValidationPhoneDigits': 'El número de teléfono debe ser de 10 digitos',
    'Error.fieldValidationPhoneOnlyDigits': 'Este campo solo puede contener números',
    'Error.fieldValidationAddressLength': 'La dirección debe tener menos de 255 caracteres',
    'Error.fieldValidationCityLength': 'La ciudad debe ser menor de 255 caracteres',
    'Error.fieldValidationZipLength': 'Código postal incompleto',
    'Error.invalidDate': 'Fecha inválida',
    'EmptyField.password': 'Por favor, introduzca su contraseña',
    'EmptyField.phoneNumber': 'Por favor introduce tu contraseña teléfono o email',
    'Error.emailrequired': 'Se requiere dirección de correo electrónico',
    'MyAccount.Error.fieldValidationPasswordLength':
      'La contraseña debe tener al menos 8 caracteres.',
    'MyAccount.Account.ConfNewPwdErrorMsg': 'Las contraseñas no coinciden',

    'Error.passwordChangingEmail':
      'Hubo un error al cambiar tu contraseña. Por favor usa de nuevo el link del correo electrónico.',
    'Error. passwordChanging': 'Revisa el campo de contraseña por errores',
    'Error.cashBackPostingPayment':
      'No se pudo completar la transacción. Por favor intenta de nuevo más tarde.',
    'Error.offerDetailsNotFound': 'Por el momento este cupón no está disponible',
    'Error.offerExpired': 'La oferta seleccionada no está disponible',
    'Success.cashBack': '¡Listo! ya transferimos tu cashback.',
    'Success. passwordUpdate': '¡Tu contraseña fue actualizada con éxito!',
    'Success.passwordReset': 'Tu contraseña fue restablecida. Por favor vuelve a iniciar sesión.',
    'Success.passwordEmailResetSent':
      'Si ya tienes una cuenta con nosotros, enviaremos un correo a la dirección que nos proporcionaste. Por favor revisa tu correo para ver los pasos siguientes.',
    'Button.signIn': 'INICIAR SESIÓN',
    'Button.login': 'INICIAR SESIÓN',
    'Button.reset': 'REINICIAR',
    'Button.update': 'ACTUALIZAR',
    'Button.yes': 'Sí',

    'Button.logout': 'CERRAR SESIÓN',
    'Button.cancel': 'CANCELAR',
    'Button.confirm': 'CONFIRMAR',
    'Button.register': 'REGISTRARSE',
    'Button.transfer': 'TRANSFERIR',
    'Button.continue': 'CONTINUAR',
    'Button.createWallet': 'CREAR EWALLET',
    'Button.send': 'ENVIAR',
    'Button.forgotPassword': 'OLVIDÉ MI CONTRASEÑA',
    'Button.toTop': 'VOLVER A ARRIBA',
    'Button.Coupon': 'Cupón',
    'Button.cashBack': 'obtener cash back',
    'Button.cashback': 'obtener cash back',
    'Button.save': 'guardar',
    'Button.clipReward': 'agregar recompensa',
    'Button.clipCoupon': 'recortar cupón',
    'Button.clippedCoupon': 'recortado',
    'Button.clippedWallet': 'cupón recortado',
    'Button.clippedWalletCashBack': 'cash back agregado',
    'Button.enroll': 'inscribirse',
    'Button.loading': 'cargando...',
    'Button.enrolled': 'inscrito',
    'Button.done': 'listo',
    'Button.updatePassword': 'Actualizar Contraseña',
    'Button.UpdatePreferStore': 'Cambiar mi tienda favorita',
    'Button.showChangeStoreOption': 'Cambiar tienda',
    'Button.channgePreferStoreLabel': 'Encuentra una tienda cercana',
    'ButtonCash.cancel': 'Cancelar',
    'ButtonCash.confirm': 'Confirmar',
    'ButtonCash.next': 'Siguiente',

    'Modal.finePrint': 'LETRAS PEQUEÑAS',
    'InputField.password': 'Contraseña',
    'Splash.saveUpTo': 'AHORRA HASTA',
    'Splash.today': 'HOY',
    'Splash.with': 'CON',
    'Splash.digitalCoupons': 'CUPONES DIGITALES!',
    'Splash.alreadyHaveAnAccount': '¿Ya tienes cuenta?',
    'Splash.emptyText': 'Por el momento no hay ofertas disponibles.',
    'Login.login': 'Iniciar Sesión',
    'Login.enterInfo': 'Agrega tu información para ver todas tus ofertas personalizadas.',
    'Login.anyField': 'Los campos con asterisco (*) son obligatorios.',
    'Button.signUp': 'REGISTRARSE',
    'login.signIn': 'Iniciar Sesión',
    'login.dontAccount': '¿No tienes cuenta?',
    'login.createAccount': 'Crear una Cuenta',

    'Account.yourAccount': 'Mi Cuenta',
    'Account.profile': 'PERFIL',
    'Account.editAccountinformation': 'EDITAR INFORMACIÓN DE PERFIL',
    'Account.accountPassword': 'CONTRASEÑA DE LA CUENTA',
    'Account.updatePassword': 'ACTUALIZAR CONTRASEÑA',
    'Account.transactionHistory': 'HISTORIAL',
    'Account.viewYourTransactionHistory': 'VER HISTORIAL',
    'Account.version': 'Tipo',
    'Account.ChangePwdTitle': 'Contraseña',
    'Account.CurrentPassword': 'Contraseña Actual',
    'Account.NewPassword': 'Nueva Contraseña',
    'Account.ConfirmNewPassword': 'Confirmar Nueva Contraseña',
    'Account.ConfNewPwdErrorMsg': 'Las contraseñas no coinciden',
    'Account.PreferredStore': 'Tienda preferida',

    'ChangePassword.header': 'Contraseña de la Cuenta',
    'ChangePassword.subHeader':
      'Ten en cuenta que esta información de contraseña se refiere únicamente a la utilizada en nuestro programa de recompensas.',

    'ChangePassword.fineText':
      'Todas las contraseñas deben tener al menos 8 caracteres. Los campos con asterisco (*) son obligatorios.',
    'ChangePassword.currentPassword': 'Contraseña Actual',
    'ChangePassword.newPassword': 'Nueva Contraseña',
    'ChangePassword.confirmPassword': 'Confirmar Nueva Contraseña',
    'Header.login': 'Iniciar Sesión',
    'Header.hello': 'Hola',
    'Header.shopper': 'Comprador',
    'Header.your': 'Mi',
    'Header.account': 'Cuenta',
    'SearchBar.myAccount': 'Perfil',
    'SearchBar.profile': 'Mi Cuenta',
    'SearchBar.myEwallet': 'Mi eWallet',
    'SearchBar. coupons': 'Cupones',
    'SearchBar.clubOffers': 'Ofertas de Club',
    'SearchBar. rewards': 'Recompensas',
    'SearchBar.eWallet': 'Mi eWallet',
    'SearchBar.cashBack': 'Cash Back',
    'SearchBar.searchForTheOffers': 'Buscar ofertas',
    'SearchBar.emailSending': 'Por favor espera mientras enviamos tu correo',
    'SearchBar.emailSent': 'Correo enviado, por favor revisa tu bandeja de entrada',
    'SubNavBar.selectOfferPool': 'Ofertas por Grupo',
    'SubNavBar.selectViewBy': 'Ver por',
    'SubNavBar.selectEmptyText': 'Seleccionar...',
    'SubNavBar.selectOptionHighestValue': 'Precio más alto',
    'SubNavBar.selectOptionNewest': 'Más recientes',
    'SubNavBar.selectOptionLowestValue': 'Precio más bajo',
    'SubNavBar.selectOptionExpiring': 'Fecha de expiración',
    'SubNavBar.selectOptionCategory': 'Categoría',

    'ForgotPassword.header': 'Olvidé mi contraseña',
    'ForgotPassword.subHeader':
      'Ingresa tu correo electrónico a continuación y te enviaremos un correo electrónico con los pasos necesarios para restablecer tu contraseña.',
    'ForgotPassword.fineText': 'Los campos con asterisco (*) son obligatorios.',
    'ForgotPassword.email': 'Correo Electrónico',
    'ForgotPassword.forgot': '¿Olvidaste tu Contraseña?',
    'Forgot.send': 'Enviar',
    'Profile.header': 'Información de la Cuenta',
    'Profile.subHeaderStart': 'Esta información solo es usada por',
    'Profile.subheaderEnd': 'y no se distribuye a ningún tercero.',
    'Profile.fineText': 'Los campos con asterisco (*) son obligatorios.',
    'Profile.updateSuccess': '¡Se actualizó tu perfil con éxito!',
    'Profile.channgePreferStoreLabel': 'Encuentra una Tienda Cercana',
    'Profile.preferredStoreDesc':
      'Las ofertas y correos electrónicos mostrarán ahorros y promociones disponibles en tu tienda elegida',
    'Profile.preferStoresSearchEmptyMsg':
      'No se encontraron tiendas en esta dirección, por favor intenta con otro código postal',
    'Profile.channgePreferStorePlaceholder': 'Buscar por Ciudad o Código Postal',
    'Profile.channgePreferStoreNoLocFoundMsg':
      'No se encontraron tiendas cercanas, te mostramos las más próximas',
    'Recommended.justClipped': 'Recién Agregado',
    'Recommended.youMightAlsoLike': 'También te podría interesar',
    'Recommended.clip': 'Recortar',
    'Recommended. clipped': 'Recortado',
    'UserHeader.totalAvailableSavings': 'Ahorros Totales Disponibles',
    'UserHeader.totalClippedSavings': 'Ahorros Totales Obtenidos',

    'UserHeader.YTDSavings': 'Ahorros del Año Hasta la Fecha',
    'UserHeader.AllClubSavings': 'Todos los Ahorros del Club Disponibles',
    'Savings.AvailableSavings': 'Ahorros Disponibles',
    'Savings.ClippedSavings': 'Ahorros Obtenidos',
    'Savings.CashOutAvailable': 'Disponible para Retirar',
    'Savings.CashOutEarned': 'Has ganado',
    'Savings.YeartoDateSavings': 'Ahorros del Año Hasta la Fecha',
    'Savings.CashOut': 'Retirar',

    'Register.header': 'Crear una Cuenta',
    'Register.subHeader':
      '¡Gracias por registrarte! Ingresa tu información a continuación para registrarte y recibir tus cupones digitales personalizados. Debes proporcionar un correo electrónico válido para registrarte. Quédate atento a las ofertas que estás por recibir.',
    'Register.fineText': 'Los campos con asterisco ( *) son obligatorios.',
    'ResetPassword.header': 'Restablecer Contraseña',
    'ResetPassword.subHeader': 'Ingresa tu nueva contraseña.',
    'ResetPassword.fineText': 'Los campos con asterisco (*) son obligatorios.',
    'ResetPassword.password': 'Contraseña',
    'ResetPassword.confirmPassword': 'Confirmar Contraseña',
    'ResetPassword.errorPasswordLengthGreaterThan8':
      'La contraseña debe tener al menos 8 caracteres',
    'ResetPassword.errorPasswordNotMatch': 'Las contraseñas no coinciden',
    'ResetPassword.subText': 'Para cambiar la contraseña, por favor ingresa una nueva contraseña.',
    'ResetPassword.reset': 'Restablecer Contraseña',
    'ResetPassword.backtoLogin': 'Regresar a Iniciar Sesión',
    'ResetPassword.loginSuccess': 'Se ha cambiado la contraseña con éxito. ',
    'ResetPassword.headerLoginSuccess': '¡Se cambió la Contraseña!',
    'ResetPassword.newPassword': 'Nueva Contraseña',
    'Rewards.totalRewardPoints': 'Puntos de Recompensa Totales',
    'Rewards.emptyText':
      '¡Ya agregaste todas las ofertas de recompensa! Por favor regresa luego para más.',
    'Register.select': 'Seleccionar',
    'Register.required': 'se requiere',

    'Rewards.bannerText': 'Puntos de Recompensa',
    'Rewards.rewardBalance': 'Saldo de Recompensas',
    'Rewards.pointsReward': 'Puntos de Recompensa',
    'ActiveClub.use': 'Usos',
    'ActiveClub.timesPerTransaction': 'Veces por Transacción',
    'ActiveClub.pointsName': 'PUNTOS',
    'ActiveClub.spendName': 'GASTADO',
    'ActiveClub.itemsName': 'COMPRADOS',

    'ClubOffers.pageTitle': 'Ofertas de clubes',
    'ClubOffers.reward': 'Premio',
    'ClubOffers.pointsName': 'Puntos',
    'ClubOffers.completed': 'Terminada',
    'ClubOffers.spendName': 'Gasta',
    'ClubOffers.earn': 'Gana',
    'ClubOffers.itemsName': 'Compra',
    'ClubOffers.locked': 'Bloqueado',
    'ClubOffers.clipped': 'Recortar',
    'ClubOffers.available': 'Recortar Oferta',
    'ClubOffers.items': 'Artículos',
    'ClubOffers.clubProgressTitle': 'Progreso en el Club',
    'ClubOffers.validDateLabel': 'Vigente Hasta',
    'ClubOffers.redeemDateLabel': 'Canjéalo para el',
    'ClubOffers.additionalDetailsTitle': 'Detalles Adicionales',
    'ClubOffers.rewardsTitle': 'Recompensas',
    'ClubOffers.unlimitedUse': 'Uso ilimitado',
    'ClubOffers.rewardsEarnedHash': '# de recompensas obtenidas',
    'ClubOffers.enroll': 'Inscribirse',
    'ClubOffers.enrolled': 'Inscrita',
    'ClubOffers.earnedRewardsTitle': 'Recompensas obtenidas',
    'ClubOffers.NextReward': 'Siguiente recompensa',
    'ClubOffers.earned': 'Ganada',
    'ClubOffers.claim': 'Reclamar recompensa',

    'ClubOffers.RewardAvailable': 'Disponible',
    'ClubOffers.noRewardEmptyMsg': 'Aún no tienes ninguna recompensa',
    'Cluboffers.earnedrewardLabel': 'Recompensa obtenida',
    'Cluboffers.redemptionRequired': 'Canje requerido',
    'Cluboffers.redeemed': 'Redimida ',
    'ClubOffers.rewardButton': 'Siguiente recompensa',
    'ClubOffers.clipRewardErrorMsg':
      'No se puede recortar la recompensa. Comuníquese con el equipo de soporte.',
    'ClubOffers.EarnedRewardDesc':
      '¡Reclama tus recompensas disponibles! Para que la próxima recompensa esté disponible, todo lo que necesita hacer es canjear la recompensa que ya reclamó. ¡Es así de simple!',
    'ClubOffers.date': 'Fecha',
    'ClubOffers.clubOffersEmptyMsgtext':
      'Comience a ganar recompensas ahora participando en un club de la izquierda. ¡Seleccione, compre y realice un seguimiento de su progreso para obtener beneficios interesantes!',
    'ClubOffers.clubOffersEmptyMsg': 'Formas de ganar',
    'ClubOffer.expiring': 'EXPIRARÁ PRONTO',
    'ClubOffer.additionalDetailsTitle': 'Detalles adicionales',
    'ClubOffers.emptyClubOffers':
      'Actualmente no hay programas de club disponibles. Por favor, vuelva más tarde',

    'MyClub.emptyText':
      '¡Aún no has obtenido ninguna recompensa del club! Por favor echa un vistazo a la sección del Club Inscrito para conocer las opciones de recompensa disponibles.',
    'Transactions.Header': 'Historial de Transacciones',

    'Transactions.subHeader':
      'Las transacciones pueden tardar hasta 48 horas en aparecer en tu cuenta.',
    'Transactions.emptyText':
      'No hay transacciones disponibles. Canjea algunas ofertas e intenta de nuevo.',
    'Transactions.columnOffer': 'Oferta',
    'Transactions.columnValue': 'Valor',
    'Transactions.columnStatus': 'Estado',
    'Transactions.columnDate': 'Fecha',
    'Wallet.tapText':
      '¡No te pierdas los ahorros! Toca sobre los artículos para marcarlos como completados.',
    'Wallet.emptyText':
      '¡No te pierdas los ahorros! Dale un vistazo a nuestras verdaderas ofertas.',
    'Wallet.viewTransactionsHistory': 'VER HISTORIAL',
    'Wallet.cashback': 'Cash back: Obtenido',
    'Wallet.discount': 'Cupón: Recortado',
    'Offers.whenYouBuy': 'Con la compra de',
    'Offers.expires': 'Vence el',
    'Offers.clipped': 'Recortado',
    'Offers.clippedUpper': 'Recortado',
    'Offers.couponDetails': 'detalles del cupón',
    'Offers.clipit': 'RECORTAR',
    'Offers.canBeUsed': 'Se puede usar hasta',
    'Offers.times': 'veces',
    'Offers.newDeal': 'NUEVA OFERTA',
    'Offers.endingSoon': 'VENCEN PRONTO',
    'Offers.congratulations': 'FELICIDADES',
    'Offers.rewardAvailable': 'Hay una oferta de recompensa disponible para recortar',
    'Offers.unlimitedUse': 'USO ILIMITADO',
    'Offers.useUpTo': 'ÚSALO HASTA',
    'Offers.purchased': 'COMPRADO',
    'Offers.earned': 'Ganado',
    'Offers.offerDetails': 'Detalles de la Oferta',
    'Offers.statusAvailable': 'DISPONIBLE',
    'Offers.statusClipped': 'RECORTADO',
    'Offers.statusRedeemed': 'CANJEADO',
    'Offers.statusExpired': 'VENCIDO',
    'Offers.points': 'Puntos',
    'Offers.endDate': 'Gana mediante: ',
    'Offers.expirationDate': 'Gana mediante: ',
    'Offers.featuredOffersUnavailable': 'La(s) oferta(s) ya no están disponibles',
    'Offers.additionalDetails': 'Detalles Adicionales',
    'Offers.hoverText': 'Oferta Flex',
    'Offers.clipBefore': 'Clip antes',
    'Offers.redeemFrom': 'Canjear desde',
    'Offers.sortBy': 'Ordenar por',

    'OffersGallery.weeklySalesLink': '¡Haz clic para ver tus ofertas semanales!',
    'OffersGallery.badSearch':
      'No pudimos encontrar lo que estabas buscando. Por favor intenta de nuevo más tarde.',
    'OffersGallery.allClipped':
      '¡Ya recortaste todas las ofertas! Por favor regresa más tarde para ver algo nuevo.',
    'OffersGallery.categoryAllClipped':
      '¡Ya recortaste todas las ofertas de esta categoría! Por favor regresa más tarde para ver algo nuevo.',
    'OffersGallery.categoryBadSearchStart':
      'No pudimos encontrar nada relacionado a tus palabras clave',
    'OffersGallery.categoryBadSearchEnd': 'en esta categoría.',

    'Category.produce': 'Frutas y Verduras',
    'Category.pet': 'Mascotas',
    'Category.beverage': 'Bebidas',
    'Category.dairy': 'Lácteos',
    'Category.deli': 'Deli',
    'Category.frozen': 'Congelados',
    'Category.general': 'General',
    'Category.grocery': 'Abarrotes',
    'Category.meat': 'Carnes y Mariscos',
    'Category.packaged_meat': 'Carnes Empaquetadas',
    'Category.snacks': 'Dulces y Snacks',

    'Category.beauty': 'Salud y Belleza',
    'Category.entertainment': 'Entretenimiento',
    'Category.books': 'Libros y Revistas',
    'Category.gifts': 'Flores y Regalos',
    'Category.auto': 'Autos',
    'Category.office': 'Artículos de Oficina',
    'Category.toys': 'Juegos y Juguetes',
    'Category.baby': 'Bebés',
    'Category.bakery': 'Panadería',
    'Category.cereal': 'Cereales y Desayunos',
    'Category.soups': 'Sopas y Productos Enlatados',
    'Category.international': 'Internacional',
    'Category.home': 'Hogar',
    'Category.pharmacy': 'Farmacia',
    'Category.alcohol': 'Cerveza, Vinos y Licores',
    'Category.tobacco': 'Productos de Tabaco',
    'Category.organic': 'Orgánicos',
    'Category.aarp': 'AARP',
    'Category.special': 'Especiales',
    'Category.baking': 'Repostería',
    'Category.kosher': 'Kosher',
    'Category.featured': 'Ofertas Destacadas',
    'Category.apparel': 'Ropa',
    'Category.electronics': 'Electrónica',
    'Category.restaurant': 'Restaurante',
    'Category.travel': 'Viaje',
    'Category.gift_card': 'Tarjetas de Regalo',
    'CashOutAccount.header': 'Agregar Cuenta',
    'CashOutAccount.subText': 'Agrega otra cuenta a tu perfil con la siguiente información.',
    'CashOutAccount.create': 'Crear',
    'CashOutAccountAdd.loyaltyid': 'ID o Número de Tarjeta de Lealtad',
    'CashOutAccountAdd.email': 'Correo Electrónico',
    'CashOutAccountAdd.description': 'Descripción',
    'CashOutAccountAdd.enabled': 'ACTIVAR',
    'CashOutAccountUpdate.header': 'Actualizar Cuentas',
    'CashOutAccountUpdate.subText': 'Puedes activar o desactivar tus cuentas a continuación.',
    'CashOutAccountUpdate.tableColumnHeaderType': 'Tipo',
    'CashOutAccountUpdate.tableColumnHeaderUserName': 'Correo/ID',
    'CashOutAccountUpdate.tableColumnHeaderDescription': 'Descripción',
    'CashOutAccountUpdate.tableColumnHeaderAction': 'Acción',
    'CashOutAccountUpdate.enable': 'ACTIVAR',
    'CashOutAccountUpdate.disable': 'DESACTIVAR',
    'CashOutAccountConfirm.message': 'Estás seguro (a) de que quieres',
    'CashOutAccountConfirm.the': 'la',
    'CashOutAccountConfirm.account': 'cuenta',
    'CashoutWrapper.paypalAccount': 'Mi cuenta Paypal',
    'CashoutButtonGrid.transfer': 'Transferir todo',

    'CashBackActive.empty':
      'Por el momento no hay ofertas de cash back. Por favor regresa más tarde para ver algo nuevo.',
    'CashBack.totalsHeader': 'Disponible para retirar en todas tus cuentas',
    'CashBack.totalsHeader2': '¿A qué cuenta te gustaría retirarlo?',
    'CashBack. redemption Redeem': 'CANJEA AQUÍ',
    'CashBack.accountHeader': 'Selecciona la cuenta que quieres usar',
    'CashBack.accountNotFoundClick': 'Haz clic',
    'CashBack.accountNotFoundHere': 'aquí',
    'CashBack.accountNotFoundToCreate': 'para crear una cuenta de este tipo',
    'CashBack.amountHeader': 'Seleccionar Cuenta',
    'CashBack.amountinputLabel': 'Monto a retirar',
    'CashBack.amountMaxValue': 'Máximo disponible para esta cuenta',
    'CashBack.confirmHeader':
      'Por favor revisa la información y confirma si quieres transferir el monto a la cuenta seleccionada',
    'CashBack.confirmAccount': 'Cuenta Seleccionada',
    'CashBack.confirmAmount': 'Monto del Retiro',
    'CashoutConfirmation.review': 'Revisar y Transferir',
    'CashoutConfirmation.typeOfPayment.loyalty': 'Tarjeta de Lealtad',
    'CashoutConfirmation.typeOfPayment.paypal': 'PayPal',
    'CashoutConfirmation.typeOfPayment.charity': 'Caridad',
    'CashoutConfirmation.transferTo': 'Transferir {amount} a',
    'CashoutConfirmation.successfullyTransfer': 'Se transfirieron con éxito {amount} a',
    'CashoutConfirmation.transferFailed': 'La transferencia falló: ',
    'CashoutConfirmation.amount': 'Monto',
    'CashoutConfirmation.edit': 'Editar',
    'Group.empty':
      'Por el momento no hay ofertas de grupo. Por favor revisa más tarde para ver algo nuevo.',
    'Group.clipAll': 'Recorta todas las ofertas a continuación',
    'Group.clipOne': 'Por favor elige una de las siguientes ofertas',
    'Group.clipMoreOffers': 'Recortar Más Ofertas',
    'OfferGroup.default': 'Por defecto',
    'OfferGroup.relevancy': 'Relevancia',
    'Navigation.gallery': 'Cupones',
    'Navigation.allclub': 'Ofertas del Club',
    'Navigation.reward': 'Recompensas',
    'Navigation.wallet': 'Cartera',
    'Navigation.profile': 'Mi Cuenta',
    'GalleryWrapper.digital': 'Cupones Digitales',
    'GalleryWrapper.coupon': 'Cupón',
    'GalleryWrapper.coupons': 'Cupones',
    'GalleryWrapper.flex': '¿Cómo quieres que te mostremos tus ofertas Flex?',
    'GalleryWrapper.flexoffer': 'Ofertas Flex',
    'GalleryWrapper.flexcashlater': 'Retirar más Tarde',
    'GalleryWrapper.flexcashback': 'obtener cash back',
    'GalleryWrapper.flexsaving': 'Ahorro instantáneo',
    'CashoutWrapper.minTransfer': 'Transferencia Mínima: $20.00',
    'CashoutWrapper.currentBalance': 'Saldo Actual: {amount}',
    // cashout wrapper select account
    'CashoutWrapper.loyaltyCardBalance': ' Saldo de la tarjeta de Lealtad: {amount}',
    'CashoutWrapper.availableToCashOut': 'Disponible para Retirar: {amount}',
    'WalletWrapper.title': 'Cartera',
    'CashoutWrapper.loading': 'Cargando Saldo...',
    'CashoutWrapper.loyalty': 'Tarjeta de Lealtad',
    'CashoutWrapper.paypal': 'PayPal',
    'CashoutWrapper.paypalEmailAddress': 'Correo Electrónico de Paypal',
    'CashoutWrapper.charity': 'Donar a la Caridad',
    'CashoutWrapper.cashout': 'Retirar',
    'Cashoutwrapper.cardbalance': 'Saldo Actual de la Tarjeta de Regalo',
    'CashoutWrapper.cashoutavail': 'Disponible para Retirar',
    'CashoutWrapper.cashoutAvailable': 'Disponible para Retirar a la Tarjeta de Lealtad',
    'CashoutWrapper.cashoutAvailablePaypal': 'Disponible para Retirar a Paypal',
    'CashoutWrapper.selectaccount': 'Selecciona una cuenta',
    'CashoutWrapper.recent': 'Transacciones Recientes',
    'CashoutWrapper.noRecentEmail': 'No hay correos recientes para las transacciones de Paypal.',
    'Filter.categories': 'Categorías',
    'Filter.clear': 'Limpiar',
    'Filter.viewResult': 'Ver resultados',

    'Skip.content': 'Ir directo al contenido principal',
    'Badge.new': 'NUEVO',
    'Badge.endingsoon': 'VENCE PRONTO',
    'Badge.expiring': 'POR VENCER',
    'Badge.startingsoon': 'COMENZANDO PRONTO',
    'Badge.featured': 'PRESENTADO',

    'CouponHistory.redeemed': 'Redimida',
    'CouponHistory.expired': 'Caducada',
    'CouponHistory.title': 'Historial de Cupones',
    'CouponHistory.offer': 'Oferta',
    'CouponHistory.status': 'Estado',
    'CouponHistory.print': 'Imprimir',

    'CouponHistory.brand': 'Marca',
    'CouponHistory.date': 'Fecha',
    'CouponHistory.clubOffersEmptyMsgtext':
      'Empieza a ganar recompensas al participar en uno de los clubes de la izquierda. ¡Selecciona, compra y monitorea tu progreso para obtener beneficios interesantes!',
    'register.alreadyAccount': '¿Ya tienes Cuenta?',
    'register.createHeader': 'Crear Cuenta',
    'register.enterInfo':
      '¡Gracias por registrarte! Ingresa tu información a continuación para completar el registro y recibir tus cupones digitales personalizados. Debes proporcionar un correo electrónico válido para registrarte. Quédate atento a las ofertas que estás por recibir.',
    'MyAccount.title': 'Mi Cuenta',
    'MyAccount.profile': 'Perfil',
    'MyAccount.uselocation': 'Usar ubicación actual',
    'MyAccount.password': 'Contraseña',
    'MyAccount.preferredstore': 'Tienda preferida',
    'MyAccount.communityrewards': 'Recompensas comunitarias',
    'MyAccount.signout': 'Desconectar',
    'MyAccount.helpful': 'Enlaces Útiles',
    'MyAccount.profileRedirectBtn': 'Ver/Editar Perfil',
    'MyAccount.manageProfileTitle': 'Administrar detalles del perfil',
    'MyAccount.manageProfileDescl':
      'Al hacer clic serás redirigido a nuestro centro de servicio de datos centralizado.',
    'MyAccount.manageProfileDesc2':
      'Recuerda volver a esta pestaña una vez que hayas terminado de hacer cambios.',
    'MyAccount.subscribe_Unsubscibe_text': 'de correos electrónicos promocionales y de marketing.',
    'MyAccount.Current Password': 'Por favor ingrese su contraseña actual',
    'MyAccount.New Password': 'Por favor ingrese su nueva contraseña',
    'MyAccount.Confirm New Password': 'Por favor ingrese su Confirmar nueva contraseña',

    'CreateAccount.marketing_text':
      'Al hacer clic en "Crear una cuenta", acepta recibir materiales promocionales y de marketing generales por correo electrónico. Puede optar por no participar en estas preferencias en cualquier momento desde Mi cuenta.',

    'Profile.updateButton': 'Guardar Cambios',
    'Profile.showmore': 'Ver Más',
    'Profile.showless': 'Ver Menos',
    'GuidedTour.welcome': 'Bienvenido',
    'GuidedTour.offerviewing': 'Opciones de Visualización de Ofertas',
    'GuidedTour.mywallet': 'Mi Cartera',
    'GuidedTour.tryitout': 'Prueba',
    'GuidedTour.back': 'Regresar',
    'GuidedTour.next': 'Siguiente',
    'GuidedTour.welcomecontent':
      '¡Ahora tienes opciones a la hora de canjear ofertas! Las ofertas Flex te permiten agregar un descuento a tu cuenta de comprador que se puede canjear en tu siguiente compra, o como valor acumulable que se puede "guardar y canjear" en forma de descuento para una compra a futuro.',
    'GuidedTour.couponcontent':
      'Los cupones son descuentos que se aplican de forma instantánea al momento de comprar. Una vez recortada la oferta a tu cuenta, la puedes canjear al hacer la compra requerida e identificándote a la hora de pagar (Por ejemplo: ingresando tu número de teléfono, escanéando la tarjeta de lealtad, o con código de barras)',
    'GuidedTour.cashbackcontent':
      'Las ofertas de cash back te permiten ganar y acumular descuentos que se pueden utilizar en compras futuras. Si las seleccionas, simplemente realiza la compra o compras requeridas y mira como se reflejan en el total de la sección "retirar".',
    'GuidedTour.flexoffercontent':
      'Las ofertas Flex dejan en tus manos elegir el cómo canjear una oferta. Puedes elegir un descuento instantáneo seleccionando la opción "Cupón" o puedes seleccionar "Cash Back" para obtener un descuento que puedes usar otro día',
    'GuidedTour.offerviewingcontent':
      'Ver las ofertas que coincidan con las preferencias de canje.',
    'GuidedTour.cashoutcontent':
      'Aquí puedes ver el cash back total acumulado. Haz clic aquí y sigue las instrucciones en pantalla para elegir el monto que quieres retirar.',
    'GuidedTour.mywalletcontent':
      'Puedes ver todas las ofertas recortadas en tu cartera. Están separadas por método de canje; En cupón o cash back.',
    'UserName.updateSuccess': '¡Se actualizó tu nombre de usuario con éxito!',
    'Error.userNameError':
      'No pudimos actualizar tu nombre de usuario, por favor revisa por errores e intenta de nuevo.',
    'username.enterinfo':
      'El botón guardar guarda este valor, te redirigirá a tu perfil después de que se actualice con éxito.',

    'Print.title': ' Lista de Compras',
    'Print.subHeadertest': 'Aquí está tu ',
    'Print.search': 'Buscar',
    'Print.sort': 'Ordenar por',
    'Print.offers': 'Detalles del artículo',
    'Print.totalcoupon': 'Total de Cupones',
    'Print.category': 'Categoría',
    'Print.expiry': 'Vigencia',
    'Print.expires': 'POR VENCER',
    'Print.emptyText':
      'No tienes cupones recortados. Recorte algunos cupones y vuelva a intentarlo.',

    'Pagetitle.Account': 'Cuenta',
    'Pagetitle.AccountPassword': 'Cuenta - Cambiar contraseña',
    'Pagetitle.AccountProfile': 'Perfil de cuenta',
    'Pagetitle.Signup': 'Inscribirse',
    'Pagetitle.Resetpassword': 'Restablecer la contraseña',
    'Pagetitle.Preferedstore': 'Cuenta - Tienda Preferida',
    'Pagetitle.Cashout': 'Retiro',
    'Pagetitle.Cluboffers': 'Ofertas de clubes',
    'Pagetitle.Couponhistory': 'Historial de cupones',
    'Pagetitle.Print': 'Imprimir cupones',
    'Pagetitle.Gallery': 'Galería de cupones',
    'Pagetitle.Forgot': 'Has olvidado tu contraseña',
    'Pagetitle.Signin': 'Iniciar sesión',
    'Pagetitle.Splash': 'Chapoteo',
    'Pagetitle.UsernameUpdate': 'Cuenta - Actualizar nombre de usuario',
    'Pagetitle.Wallet': 'Cartera electrónica',
    'Pagetitle.Rewards': 'Recompensas',
    'Pagetitle.Fueloffers': 'Ofertas de combustible',

    'Rewards.Rewards': 'Recompensas',
    'Rewards.expires': 'Vence: ',
    'Rewards.locked': 'Bloqueado',
    'Rewards.Amount_Spent': 'Cantidad gastada',
    'Rewards.Points_Earned': 'Puntos ganados',
    'Rewards.Item_Purchased': 'Artículos comprados',
    'Rewards.available_to_claim': 'Disponible para reclamar',
    'Rewards.Rewards_Claimed': 'Recompensas reclamadas',
    'Rewards.view_rewards': 'ver recompensas disponibles',
    'Rewards.claim': 'Afirmar',
    'Rewards.Item': 'Elementos',
    'Rewards.Points': 'Puntos',
    'Rewards.Spent': 'Gastada',
    'Rewards.loyaltyProgramEmptyMsg': 'Bienvenida a los programas de lealtad',
    'Rewards.loyaltyProgramEmptyMsgText': 'Bienvenido a los programas de fidelización. ',
    'Rewards.waysToEarnTitle': 'Formas de ganar',
    'Rewards.spentReward': 'Recompensa gastada',
    'Rewards.itemsReward': 'Recompensa de artículos',
    'Rewards.pointReward': 'Recompensa de puntos',
    'Rewards.welcomeMsg': 'Bienvenida a ',
    'Rewards.loyaltyProgram': 'Programa de fidelización de recompensas',
    'Rewards.loyaltyEarnpointsTxt':
      '¡Gane puntos en compras seleccionadas para gastar en una variedad de recompensas!',
    'Rewards.loyaltyEmptyProgram':
      'Actualmente no hay programas de recompensas disponibles. Por favor, vuelva más tarde',

    'Language.English': 'Inglés (US)',
    'Language.French': 'Francés (FR)',
    'Language.Spanish': 'Español (MX)',

    'FuelOffer.rewardTab': 'Recompensas',
    'FuelOffer.fuelTab': 'Recompensas de combustible',
    'FuelOffer.earnedLabel': 'Ganada',
    'FuelOffer.redeemedLabel': 'Redimida',
    'FuelOffer.expiredLabel': 'Caducada',
    'FuelOffer.earnedon': 'Obtenido en ',
    'FuelOffer.expireson': 'Expira el ',
    'FuelOffer.redeemedon': 'Canjeado el ',
    'FuelOffer.expired': 'Caducada el ',
    'FuelOffer.leftTitle': 'Recompensas de combustible',
    'FuelOffer.points': 'Puntos',
    'FuelOffer.nextRewardTxt': 'Centavos de descuento por galón',
    'FuelOffer.save': 'Ahorrar',
    'FuelOffer.fuelPoints': 'Puntos de combustible',
    'FuelOffer.more': 'más puntos por un adicional',
    'FuelOffer.cent': 'centavos de descuento por galón!',
    'FuelOffer.centOff': 'centavos de descuento',
    'FuelOffer.description':
      'Se muestran las transacciones de los últimos 31 días, los puntos de combustible caducan después de 30 días',
    'FuelOffer.availablesavings': 'Ahorros disponibles por galón',
    'FuelOffer.Earn': 'Ganar',
    'FuelOffer.noRewards': 'No hay recompensas disponibles actualmente',

    'CommunityReward.enrolled': 'Inscrita',
    'CommunityReward.enroll': 'Inscribirse',
    'CommunityReward.viewWeb': 'Ver sitio web',
    'CommunityReward.rightTitle': 'Recompensas comunitarias',
    'CommunityReward.search': 'Buscar',
    'CommunityReward.modalAreYouSure':
      'Elegir una organización diferente reemplazará su organización existente.',
    'CommunityReward.confirmationTxt': '¿Quieres continuar?',
    'CommunityReward.noEnrollMsg':
      'No estás inscrito en ningún programa comunitario. Por favor inscríbete para comenzar tu participación',
  },
};
export default Object.freeze({ ...messages });
