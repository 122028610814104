import constants from '../constants';

const initialState = {
  totalOffers: 0,
  offers: [],
  isLoading: false,
  fetchMore: true,
  totalPages: 1,
};

export default function galleryOffersReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_GALLERY_OFFERS:
      const { offers, totalOffers } = action.payload;

      return { ...state, offers: [...state.offers, ...offers], totalOffers };
    case constants.SET_LOADING_OFFERS:
      return { ...state, isLoading: action.payload };
    case constants.SET_FETCH_MORE_GALLERY:
      return { ...state, fetchMore: action.payload };
    case constants.SET_TOTAL_PAGES:
      return { ...state, totalPages: action.payload };
    case constants.CLEAR_OFFERS:
      return { ...state, offers: [], isLoading: true };
    default:
      return state;
  }
}
