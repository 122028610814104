import constants from '../constants';

export default function category(category = [], action) {
  switch (action.type) {
    case constants.CATEGORY_OFFER_DATA:
      return action.data;
      break;
    default:
      return category;
  }
}
