import constants from '../constants';

const initialState = {
  totalFeaturedOffers: 0,
  featuredOffers: [],
  isLoading: false,
  fetchMore: true,
  totalOfferPages: 1,
  galleryFeaturedId: [],
};

export default function galleryFeaturedOffersReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_FEATURED_OFFERS:
      const { featuredOffers, totalFeaturedOffers, galleryFeaturedId, totalOfferPages } =
        action.payload;
      return {
        ...state,
        featuredOffers: [...state.featuredOffers, ...featuredOffers],
        totalFeaturedOffers,
        totalOfferPages,
        galleryFeaturedId: [...state.galleryFeaturedId, ...galleryFeaturedId],
      };
    case constants.CLEAR_OFFERS:
      return { ...state, featuredOffers: [] };
    default:
      return state;
  }
}
