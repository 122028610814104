import constants from '../constants';

const initialState = {
  couponHistory: [],
};

export default function couponHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_COUPON_HISTORY_V3:
      return { ...state, couponHistory: action.data };
    default:
      return state;
  }
}
