import { createTheme, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsMobile } from '../redux/slices/isMobileSlice';

export function PagesWrapper({ children }) {
  const dispatch = useDispatch();

  const theme = createTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(setIsMobile(isMobile));
  }, [isMobile, dispatch]);

  return <>{children}</>;
}
