import constants from '../constants';

export default function userV3Reducer(user = {}, action) {
  switch (action.type) {
    case constants.GET_RESET_PASSWORD_V3:
      return action.data;
      break;
    case constants.GET_USER_ERROR_V3:
      return action.data;
      break;
    case constants.GET_USER_DATA_V3:
      return action.data;
      break;
    default:
      return user;
  }
}
