import constants from '../constants';

export default function userNameReducer(user = {}, action) {
  switch (action.type) {
    case constants.UPDATE_USERNAME:
      return action.data;
    default:
      return user;
  }
}
