import constants from '../constants';

export default function transactionReducer(transaction = {}, action) {
  switch (action.type) {
    case constants.GET_TRANSACTIONS:
      return action.data;
      break;
    default:
      return transaction;
  }
}
