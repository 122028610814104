import React from 'react';
import axios from 'axios';
import ConfigError from '../ConfigError';
import config from '../config/config';

// Error boundaries currently have to be classes.
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: { any: true },
      error,
    };
  }

  render() {
    const { children } = this.props;
    if (this.state.hasError) {
      return <ConfigError errorType={this.state.hasError} />;
    }
    return children;
  }
}

export default ErrorBoundary;
