import constants from '../constants';

const obj = {
  gallery: 'relevant',
  wallet: 'clipEndDate',
};

export default function sortByFilterReducer(option, action) {
  switch (action.type) {
    case constants.SET_SORTBY_GALLERY:
      obj.gallery = action.payload;
      return obj;
      break;
    case constants.SET_SORTBY_WALLET:
      obj.wallet = action.payload;
      return obj;
      break;
    default:
      return obj;
  }
}
