import constants from '../constants';

export default function userReducer(recommended = [], action) {
  switch (action.type) {
    case constants.GET_RECOMMENDED:
      return action.data;
      break;
    default:
      return recommended;
  }
}
