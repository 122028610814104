import constants from '../constants';

export default function clubActiveReducer(clubActive = [], action) {
  switch (action.type) {
    case constants.CLUB_ACTIVE_GET:
      return action.data;
    case constants.CLUB_ACTIVE_CLIP:
      return action.data;
    case constants.CLUB_ACTIVE_GET_KEYWORDS:
      return action.data;
    case constants.CLUB_ACTIVE_GET_VIEW_BY:
      return action.data;
    case constants.CLUB_ACTIVE_CLEAR:
      return action.data;
    default:
      return clubActive;
  }
}
