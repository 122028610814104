import constants from '../constants';

export default function groupReducer(group = {}, action) {
  switch (action.type) {
    case constants.GROUP_GET:
      return action.data;
      break;
    case constants.GROUP_CLEAR:
      return [];
      break;
    default:
      return group;
  }
}
