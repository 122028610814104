import constants from '../constants';

export default function userReducer(user = {}, action) {
  switch (action.type) {
    case constants.LOGIN_USER:
      return action.data;
      break;
    case constants.LOGOUT_USER:
      return action.data;
      break;
    case constants.CREATE_USER:
      return action.data;
      break;
    case constants.GET_USER:
      return action.data;
      break;
    case constants.UPDATE_USER:
      return action.data;
      break;
    case constants.FORGOT_PASSWORD:
      return action.data;
      break;
    case constants.CHANGE_PASSWORD:
      return action.data;
      break;
    case constants.USER_ERROR:
      return action.data;
      break;
    case constants.SEND_EMAIL_LIST:
      return action.data;
      break;
    case constants.CLEAR_USER:
      return action.data;
      break;
    case constants.RESET_PASSWORD:
      return action.data;
      break;
    default:
      return user;
  }
}
