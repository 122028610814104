import constants from '../constants';

const initState = {
  loading: false,
  loadingClipping: false,
  loadingEnrolling: false,
  rewardsPrograms: null,
  errorMsg: null,
};

export default function rewardsV3Reducer(state = initState, action) {
  switch (action.type) {
    case constants.GET_REWARDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case constants.CLIP_REWARDS_REQUEST:
      return {
        ...state,
        loadingClipping: true,
      };
    case constants.ENROLL_PROGRAM_REQUEST:
      return {
        ...state,
        loadingEnrolling: true,
      };
    case constants.GET_REWARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardsPrograms: action.rewardsPrograms,
      };
    case constants.CLIP_REWARDS_SUCCESS:
      return {
        ...state,
        loadingClipping: false,
        clippedOffer: action.clippedOffer,
      };

    case constants.ENROLL_PROGRAM_SUCCESS:
      return {
        ...state,
        loadingEnrolling: false,
        enrolledProgram: action.enrolledProgram,
      };
    case constants.GET_REWARDS_ERROR:
    case constants.CLIP_REWARDS_ERROR:
    case constants.ENROLL_PROGRAM_ERROR:
      return {
        ...state,
        loading: false,
        loadingClipping: false,
        loadingEnrolling: false,
        errorMsg: action.error,
      };
    case constants.CLEAR_REWARDS:
    default:
      return state;
  }
}
