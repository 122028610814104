import constants from '../constants';

const obj = {
  flexOffer: window.localStorage.getItem('flexOfferType') || 'flex',
};

export default function flexHybridOffer(option, action) {
  switch (action.type) {
    case constants.SET_FLEX_HYBRID_OFFER:
      obj.flexOffer = action.payload;
      return obj;
    default:
      return obj;
  }
}
