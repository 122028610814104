import constants from '../constants';

const initialState = {
  totalCategories: 0,
  categories: [],
  clippedCategories: [],
  totalClippedCategories: 0,
};

export default function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_CATEGORIES:
      const { categories, totalCategories } = action.payload;
      return { ...state, categories, totalCategories };
    case constants.SET_CLIPPED_CATEGORIES:
      const { clippedCategories, totalClippedCategories } = action.payload;
      return { ...state, categories: clippedCategories, totalCategories: totalClippedCategories };
    default:
      return state;
  }
}
