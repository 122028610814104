import constants from '../constants';

const initState = {
  errorMsg: null,
  clubPrograms: [],
};

export default function clubOffersReducer(state = initState, action) {
  switch (action.type) {
    case constants.GET_CLUB_OFFERS_REQUEST:
      return {
        ...state,
        clubPrograms: action.data,
      };
    case constants.UPDATE_CLUB_OFFER_REQUEST:
      const clubOffers = state.clubPrograms;
      const index = clubOffers.findIndex((o) => o.id === action.data.id);
      clubOffers[index] = action.data;

      return {
        ...state,
        clubPrograms: [...clubOffers],
      };
    default:
      return state;
  }
}
