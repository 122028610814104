import { combineReducers } from 'redux';

import offerReducer from './offerReducer';
import userReducer from './userReducer';
import storeReducer from './storeReducer';
import keyWordsReducer from './keyWordsReducer';
import keyWordsV3Reducer from './keyWordsV3Reducer';
import categoryReducer from './categoryReducer';
import walletReducer from './walletReducer';
import transactionReducer from './transactionReducer';
import recommendedReducer from './recommendedReducer';
import configReducer from './configReducer';
import clubProgramsReducer from './clubProgramsReducer';
import clubActivereducer from './clubActiveReducer';
import clubOffersReducer from './clubOffersReducer';
import clubWalletReducer from './clubWalletReducer';
import rewardReducer from './rewardReducer';
import rewardsV3Reducer from './rewardsV3Reducer';
import charityReducer from './charityReducer';
import cashBackReducer from './cashBackReducer';
import cashOutReducer from './cashOutReducer';
import accountTypeReducer from './accountTypeReducer';
import accountReducer from './accountReducer';
import groupReducer from './groupReducer';
import landingPageReducer from './landingPageReducer';
import notificationsReducer from './notificationReducer';
import locationReducer from './locationReducer';
import galleryOffersV3Reducer from './galleryOffersV3Reducer';
import translationV3Reducer from './translationV3Reducer';
import offerCategoriesReducer from './offerCategoriesReducer';
import clippedV3Reducer from './clippedV3Reducer';
import categoriesV3Reducer from './categoriesV3Reducer';
import walletOffersReducer from './walletOffersV3Reducer';
import sortByFilterReducer from './sortByFilterReducer';
import cashbackV2Reducer from './cashbackV2Reducer';
import flexHybridOfferReducer from './flexHybridOfferReducer';
import { reducer as isMobileReducer } from '../slices/isMobileSlice';
import userV3Reducer from './userV3reducer';
import couponHistoryReducer from './couponHistoryReducer';
import userNameReducer from './userNameReducer';
import galleryFeaturedOffersReducer from './galleryFeaturedOfferReducer';

const rootReducer = combineReducers({
  offer: offerReducer,
  user: userReducer,
  store: storeReducer,
  keyWords: keyWordsReducer,
  keyWordsV3: keyWordsV3Reducer,
  category: categoryReducer,
  wallet: walletReducer,
  transaction: transactionReducer,
  recommended: recommendedReducer,
  retailerConfig: configReducer,
  clubs: clubProgramsReducer,
  clubActive: clubActivereducer,
  clubOffers: clubOffersReducer,
  clubWallet: clubWalletReducer,
  reward: rewardReducer,
  rewardsV3: rewardsV3Reducer,
  charity: charityReducer,
  cashBack: cashBackReducer,
  cashOut: cashOutReducer,
  accountType: accountTypeReducer,
  account: accountReducer,
  group: groupReducer,
  landingPage: landingPageReducer,
  notifications: notificationsReducer,
  storeLocation: locationReducer,
  galleryOffersV3: galleryOffersV3Reducer,
  translationV3: translationV3Reducer,
  offerCategories: offerCategoriesReducer,
  clippedV3Data: clippedV3Reducer,
  categoriesV3: categoriesV3Reducer,
  walletOffersV3: walletOffersReducer,
  isMobile: isMobileReducer,
  sortByFilter: sortByFilterReducer,
  cashbackV2: cashbackV2Reducer,
  flexHybridOffer: flexHybridOfferReducer,
  userForgotMessage: userV3Reducer,
  couponHistory: couponHistoryReducer,
  userNameUpdated: userNameReducer,
  galleryFeatureOffer: galleryFeaturedOffersReducer,
});

export default rootReducer;
