import constants from '../constants';

const initState = {
  keywords: [],
};

export default function keywordsV3Reducer(state = initState, action) {
  switch (action.type) {
    case constants.GET_KEY_WORDSV3:
      return { keywords: action.payload };
    default:
      return state;
  }
}
