import { createIntl, createIntlCache } from 'react-intl';
import languages from '../../languages/languages';
import constants from '../constants';

const cache = createIntlCache();
const initialState = {
  intl: createIntl(
    {
      locale: 'en',
      messages: languages.en,
    },
    cache
  ),
  locale: 'en',
};

export default function translationV3Reducer(state = initialState, { type, payload }) {
  switch (type) {
    case constants.SET_TRANSLATION:
      const { intl, locale } = payload;
      return { intl, locale };
    default:
      return state;
  }
}
