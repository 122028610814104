// Settings configured here will be merged into the final config object and overwrote by the specific retailer info.
export default {
  // 'name': 'inmart',
  name: 'inmart', // this is the first target //also this is the x-retailer
  displayName: 'inmart',
  helloUserAccountElementsToDisplay: 'firstName',
  headerProgramName: '',
  originUrl: 'http://localhost:3000',
  mainColor: '#000000',
  mainColorRGB: '82, 184, 70', // only used for hamburger active link state
  mainColorHover: '#000000',
  notificationMainColor: '',
  notificationSecondaryColor: '',
  logo: 'inmart-logo.png', // This is the logo image
  favicon: '',
  privacyPolicy: 'https://www.inmar.com/about/privacy-policy',
  termsAndConditions: 'https://www.inmar.com/terms-conditions/website-terms-of-use',
  weeklyAdLink: '',
  hideWalletCount: false,
  languages: [],
  componentsVersion: '1.0',
  enableFlexGuidedTour: false,
  enableFlexGalleryFilter: false,
  offerTemplateType: 'shortDescription', // currently original or shortDescription
  Enabled_CashbackChannels: ['PAYPAL', 'INSTORE'],
  navigationConfig: [
    {
      type: 'gallery',
      activated: true,
      label: 'Coupons',
    },
    {
      type: 'allclub',
      activated: true,
      label: 'Club Offers',
    },
    {
      type: 'reward',
      activated: true,
      label: 'Rewards',
    },
    {
      type: 'wallet',
      activated: true,
      label: 'Wallet',
    },
    {
      type: 'profile',
      activated: true,
      label: 'My Account',
    },
  ],
  embeddingConfig: { embeddingActive: false, navDisabled: false },
  turnOffRecommended: true,
  /* "translatedDisplayName": {
    "en": "Giant Tiger",
    "fr": "Tigre Géant"
  },
  "translatedLogo": {
    "en": "https://images.inmaroffers.com/gianttiger-logo.png",
    "fr": "https://images.inmaroffers.com/gianttiger-logo-fr_CA.png"
  },
  "loginPlaceHolderTextInternationalization": {
    'en': "Email or Phone Number",
    'fr': 'Courriel ou numéro de téléphone'
  }, */
  // Used on splash page
  useSplash: false,
  splashBackground: 'splash_background_inmart.png',
  subColor: '#000000',
  subColorHover: '#000000',
  loginPlaceHolderText: 'Email or Phone Number',

  formRegisterPhoneNumberLength: 10,
  formUpdatePhoneNumberLength: 10,
  formRegisterZipLength: 5,
  formUpdateZipLength: 5,
  unAuthLogoRedirect: '',
  authLogoRedirect: '',

  // Controls the fields on teh register page
  formRegisterUserNameKey: 'phone', // this is the field that is used for the username ex: phone, email,  or cardId
  formRegisterAltIdKey: '', // this is the field that is used for the username ex: phone, email,  or cardId. Leave blank to disable
  formRegisterFirstNameShow: true,
  formRegisterFirstNameRequired: true,
  formRegisterLastNameShow: true,
  formRegisterLastNameRequired: true,
  formRegisterEmailAddressShow: true,
  formRegisterEmailAddressRequired: true,
  formRegisterPrimaryStoreShow: true,
  formRegisterPrimaryStoreRequired: true,
  formRegisterCardIdShow: false,
  formRegisterCardIdRequired: true,
  formRegisterLoyaltyIdShow: false,
  formRegisterLoyaltyIdRequired: false,
  formRegisterPasswordShow: true,
  formRegisterPasswordRequired: true,
  formRegisterConfirmPasswordShow: true,
  formRegisterConfirmPasswordRequired: true,
  formRegisterPhoneNumberShow: true,
  formRegisterPhoneNumberRequired: true,
  formRegisterAddressOneShow: true,
  formRegisterAddressOneRequired: true,
  formRegisterAddressTwoShow: false,
  formRegisterAddressTwoRequired: true,
  formRegisterCityShow: true,
  formRegisterCityRequired: true,
  formRegisterStateShow: true,
  formRegisterStateRequired: true,
  formRegisterZipShow: true,
  formRegisterZipRequired: true,

  // Controls the fields on the profile update page
  formUpdateFirstNameShow: true,
  formUpdateFirstNameRequired: false,
  formUpdateLastNameShow: true,
  formUpdateLastNameRequired: false,
  formUpdateEmailAddressShow: true,
  formUpdateEmailAddressRequired: false,
  formUpdateEmailAddressDisable: false,
  formUpdatePhoneNumberShow: true,
  formUpdatePhoneNumberDisable: true,
  formUpdateLoyaltyIdShow: false,
  formUpdateLoyaltyIdRequired: false,
  formUpdateLoyaltyIdDisable: false,
  formUpdatePhoneNumberRequired: true,
  formUpdatePrimaryStoreShow: true,
  formUpdatePrimaryStoreRequired: true,
  formUpdateBirthDayShow: false,
  formUpdateBirthDayRequired: false,
  formUpdateAddressOneShow: true,
  formUpdateAddressOneRequired: false,
  formUpdateAddressTwoShow: false,
  formUpdateAddressTwoRequired: false,
  formUpdateCityShow: true,
  formUpdateCityRequired: false,
  formUpdateStateShow: true,
  formUpdateStateRequired: false,
  formUpdateZipShow: true,
  formUpdateZipRequired: false,

  // Controls if the retailer allows adding rewards card
  allowAddRewardsCard: true,
  // Controls if the retailer allows for club offers
  allowClubOffers: false,
  // Controls if the retailer allows for tiered offers
  allowRewardOffers: false,
  // Controls if the retailer allows for cash back
  allowCashBack: false,
  // Controls if the My Account will be displayed in navbar
  allowProfile: true,
  // Controlls enabling offers
  allowOffers: true,
  // Controlls enabling group offers,
  allowGroupOffers: false,
  // Controlls enableing email button on wallet print bar
  allowEmailOnWallet: true,
  // Controls enabling SSO
  ssoLoginURL: '',
  ssoRegisterURL: '',
  ssoProfileURL: '',
  ssoPasswordURL: '',
  ssoLogoutURL: '',
  hamburgerHideProfile: false,
  hamburgerHidePassword: false,
  // allows retailers to add custom value to calculate the spend amount in dollars or cents
  spendProgramDenominationTestAmount: 1000,
  contextualTargeting: [],
  offerPoolUIDisplay: {
    gallery: false,
  },
  // allows retailers to add custom language and overwrite the injection
  /* offerPoolUIDisply: {
    gallery: true,
  },
  offerPoolDefault: {
    splash: 'default',
    gallery: 'default',
  },
  offerPoolCustom: {
    gallery: {
      dynamic: {
        url: '/offers/ranked?contextTypes=targeted,unranked&matchRunId=dynamic',
        requestParams: true,
        displayLabel: {
          en: 'Dynamic',
          fr: 'Le Dynamic',
        },
      },
    },
  }, */
  customLanguage: {
    /* "en": {
      "SubNavBar.navigationGallery": "Custom Gallery"
    },
    "fr": {
      "SubNavBar.navigationGallery": "Custom Galerie"
    } */
  },
  landingPages: {} /* {
    "test": { //Name of landing page that ties to URL param
      "landingPageType": "standard",
      "region": { //This can be taliored toward the landing page type
        "header": {
          "background": "#EF4B52",
          "height": "50px",
          "items": [{
            "type": "label", //type can be expanded in the future depending on what the retailer wants added
            "color": "#fff",
            "label": {
              "en": "Header message",
              "fr": "Le Header message"
            }
          }]
        },
        "banner": {
          "type": "single",//single | carousel
          "carouselOptions": {
            //...only used for carousel
            "height": 17.5, //needs to be played with since idk the measurment, might be a percent of the initial screen 
            "intervale": 5000 // in ms 
          },
          "images": {
            "en": [
              {
                "url": "http://d3iu2m4dhm5ec4.cloudfront.net/riteaid/site/images/features/facebook.png",
                "link": "http://www.google.com",
                "alt": "Alt text english"
                //...could inject other options here for carousel
              },
              {
                "url": "http://d3iu2m4dhm5ec4.cloudfront.net/riteaid/site/images/features/facebook.png",
                "link": "http://www.google.com",
                "alt": "Alt text english"
                //...could inject other options here for carousel
              }
            ],
            "fr": [
              {
                "url": "http://d3iu2m4dhm5ec4.cloudfront.net/riteaid/site/images/features/facebook.png",
                "link": "http://www.google.com",
                "alt": "Alt text french"
                //...could inject other options here for carousel
              },
              {
                "url": "http://d3iu2m4dhm5ec4.cloudfront.net/riteaid/site/images/features/facebook.png",
                "link": "http://www.google.com",
                "alt": "Alt text french"
                //...could inject other options here for carousel
              }
            ]
          }
        },
        "offersGallery": {
          ///...Will get the offerTemplateType from the retailer so the display is consistent throught Shopper Site
          "iceOfferCall": "/offers?ids=357593,357591", //we only need to do the specific route, SS will provide the root
          "offerTemplateType": "shortDescription", //same as main program, shortDescription or original
          "offerTemplateConfig": {
            "mainButtonColor": "#ef4b52",
            "mainButtonHoverColor": "#cc3f45",
            "mainButtonTextColor": "#ffffff",
            "bannerColor": "#ef4b52", //used on both templates
            "bannerTextColor": "#ffffff", //used on both templates
            "usedByTextColor": "", //only original
            "mobileAddButton": "", //only original
            "hoverBackgroundColor": "", //only original
            "hoverTextColor": "" //only original
          },
          "clipAction": {
            "type": "standard",//standard | anonymous | link
            "clipChannel": "",
            "clipFunnel": "",
            //... rest of options for clip action type
          }
        },
        "buttonBar": {
          "buttonDefinition": {
            "primary": {
              "color": "#fff",
              "background": "#EF4B52",
              "hoverBackground": "#c94045"
            },
            "secondary": {
              "color": "#fff",
              "background": "#EF4B52",
              "hoverBackground": "#EF4B51"
            }
          },
          "buttons": [
            {
              "type": "primary",
              "label": {
                "en": "MORE OFFERS",
                "fr": "French for MORE OFFERS" //... we should build this in initially all labels will require this
              },
              "action": "linkTo",//"linkToGallery | clipAll"
              "url": "/gallery" //no http is internal 
            }
          ]
        },
        "footer": {
          "background": "#EF4B52",
          "height": "50px",
          "items": [
            {
              "type": "copyWrite",
              "color": "#fff",
              "label": {
                "en": "Inmart",
                "fr": "Le Inmart"
              }
            },
            {
              "type": "link",
              "color": "#fff",
              "hoverColor": "#6c757d",
              "label": {
                "en": "Terms and Conditions",
                "fr": "French for Terms and Conditions"
              },
              "url": "http://google.com"
            }
          ]
        }
      }
    }
  } */,
};
