import constants from '../constants';

export default function accountReducer(account = [], action) {
  switch (action.type) {
    case constants.ACCOUNT_GET:
      return action.data;
      break;
    case constants.ACCOUNT_CREATE:
      return action.data;
      break;
    case constants.ACCOUNT_UPDATE:
      return action.data;
      break;
    case constants.ACCOUNT_DELETE:
      return action.data;
      break;
    case constants.ACCOUNT_CLEAR:
      return action.data;
      break;
    default:
      return account;
  }
}
