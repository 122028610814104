import constants from '../constants';
import baseRetailer from '../../config/baseRetailer';

const initialState = { retailer: baseRetailer, isLoading: true };
export default function configReducer(config = initialState, action) {
  switch (action.type) {
    case constants.GET_CONFIG:
      return action.data;
    default:
      return config;
  }
}
