import constants from '../constants';

const initialState = {
  cardBalance: 0,
  balances: [],
  balancesTotal: 0,
  transactions: [],
};

export default function cashbackReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_CASHBACK_CARD_BALANCE:
      return { ...state, cardBalance: action.payload };
    case constants.SET_CASHBACK_BALANCES:
      return { ...state, balances: action.payload, balancesTotal: 0 };
    case constants.SET_CASHBACK_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    default:
      return state;
  }
}
