import { PERSISTENT_ENVS } from '../util/constants';

const shoppersiteHosts = ['inmaroffers', 'inmarpromotions', 'mycalgarycoop'];
if (!PERSISTENT_ENVS.includes(process.env.REACT_APP_ENV_NAME)) {
  shoppersiteHosts.push(process.env.REACT_APP_LOCALHOST);
}
const isHostedByInmar = shoppersiteHosts.find((shoppersiteHostname) =>
  window.location.hostname.includes(shoppersiteHostname)
);

function getCustomURLRetailerName() {
  const url = window.location.hostname.split('.com');
  const splitUrl = url[0].split('.');

  return splitUrl[splitUrl.length - 1];
}

const retailerName = isHostedByInmar
  ? window.location.hostname.split('.')[0]
  : getCustomURLRetailerName();

const apiConfig = {
  appEnv: process.env.REACT_APP_ENV_NAME,
  debug: process.env.REACT_APP_DEBUG,
  configUrl: window.location.host,
  retailer: { retailerName },
  isLoading: true,
};

export default apiConfig;
