import constants from '../constants';

export default function storeReducer(store = [], action) {
  switch (action.type) {
    case constants.GET_ALL_STORE_LOCATIONS:
      return action.data;
      break;
    default:
      return store;
  }
}
