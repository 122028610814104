const constants = {
  GET_ALL_OFFER_DATA: 'GET_ALL_OFFER_DATA',
  GET_OFFER_DATA: 'GET_OFFER_DATA',

  GET_KEYWORD_OFFER_DATA: 'GET_KEYWORD_OFFER_DATA',
  VIEW_BY_OFFER_DATA: 'VIEW_BY_OFFER_DATA',
  CATEGORY_OFFER_DATA: 'CATEGORY_OFFER_DATA',
  LOGIN_USER: 'LOGIN_USER',
  LOGOUT_USER: 'LOGOUT_USER',
  CREATE_USER: 'CREATE_USER',
  UPDATE_USER: 'UPDATE_USER',
  GET_USER: 'GET_USER',
  SET_USER: 'SET_USER',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  SEND_EMAIL_LIST: 'SEND_EMAIL_LIST',
  USER_ERROR: 'USER_ERROR',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  GET_ALL_STORE_LOCATIONS: 'GET_ALL_STORE_LOCATIONS',
  GET_MAP_LOCATIONS_DATA: 'GET_MAP_LOCATIONS_DATA',
  GET_KEY_WORDS: 'GET_KEY_WORDS',
  GET_KEY_WORDSV3: 'GET_KEY_WORDSV3',
  GET_WALLET: 'GET_WALLET',
  CLIP_OFFER: 'CLIP_OFFER',
  CLIP_ALL_OFFER: 'CLIP_ALL_OFFER',
  CLIP_OFFER_ERROR: 'CLIP_OFFER_ERROR',
  CLIP_OFFER_GALLERY: 'CLIP_OFFER_GALLERY',
  CLIP_OFFER_GALLERY_SUCCESS: 'CLIP_OFFER_GALLERY_SUCCESS',
  CLIP_OFFER_GALLERY_ERROR: 'CLIP_OFFER_GALLERY_ERROR',
  GET_TRANSACTIONS: 'GET_TRANSACTIONS',
  GET_RECOMMENDED: 'GET_RECOMMENDED',
  WALLET_CLEAR: 'WALLET_CLEAR',
  OFFER_CLEAR: 'OFFER_CLEAR',
  CATEGORY_ERROR: 'CATEGORY_ERROR',
  CLEAR_USER: 'CLEAR_USER',
  GET_CONFIG: 'GET_CONFIG',

  // v3 clipping
  CLIPPED_V3: 'CLIPPED_V3',

  // v3 Users reset and login
  GET_RESET_PASSWORD_V3: 'GET_RESET_PASSWORD_V3',
  GET_USER_ERROR_V3: 'GET_USER_ERROR_V3',
  GET_USER_DATA_V3: 'GET_USER_DATA_V3',
  LOGOUT_USER_V3: 'LOGOUT_USER_V3',
  UPDATE_USERNAME: 'UPDATE_USERNAME',
  // end V3 Users reset and login
  // v3 club actions

  GET_CLUB_PROGRAMS_REQUEST: 'GET_CLUB_PROGRAMS_REQUEST',
  GET_CLUB_PROGRAMS_SUCCESS: 'GET_CLUB_PROGRAMS_SUCCESS',
  GET_CLUB_PROGRAMS_ERROR: 'GET_CLUB_PROGRAMS_ERROR',

  // V3- CLUB offers
  GET_CLUB_OFFERS_REQUEST: 'GET_CLUB_OFFERS_REQUEST',

  ENROLL_CLUB_PROGRAM_REQUEST: 'ENROLL_CLUB_PROGRAM_REQUEST',
  ENROLL_CLUB_PROGRAM_SUCCESS: 'ENROLL_CLUB_PROGRAM_SUCCESS',
  ENROLL_CLUB_PROGRAM_ERROR: 'ENROLL_CLUB_PROGRAM_ERROR',

  CLIP_CLUB_REWARD_OFFER_REQUEST: 'CLIP_CLUB_REWARD_OFFER_REQUEST',
  CLIP_CLUB_REWARD_OFFER_REQUEST_SUCCESS: 'CLIP_CLUB_REWARD_OFFER_REQUEST_SUCCESS',
  CLIP_CLUB_REWARD_OFFER_REQUEST_ERROR: 'CLIP_CLUB_REWARD_OFFER_REQUEST_ERROR',
  CLEAR_CLIP_REWARD_OFFER: 'CLEAR_CLIP_REWARD_OFFER',

  CLEAR_CLUB_PROGRAMS: 'CLEAR_CLUB_PROGRAMS',

  // end v3 club actions
  UPDATE_CLUB_OFFER_REQUEST: 'UPDATE_CLUB_OFFER_REQUEST',
  CLUB_WALLET_GET: 'CLUB_WALLET_GET',
  CLUB_WALLET_CLIP: 'CLUB_WALLET_CLIP',
  CLUB_WALLET_CLEAR: 'CLUB_WALLET_CLEAR',

  CLUB_ACTIVE_GET: 'CLUB_ACTIVE_GET',
  CLUB_ACTIVE_CLIP: 'CLUB_ACTIVE_CLIP',
  CLUB_ACTIVE_GET_KEYWORDS: 'CLUB_ACTIVE_GET_KEYWORDS',
  CLUB_ACTIVE_GET_VIEW_BY: 'CLUB_ACTIVE_GET_VIEW_BY',
  CLUB_ACTIVE_CLEAR: 'CLUB_ACTIVE_CLEAR',

  REWARDS_GET: 'REWARDS_GET',
  REWARDS_CLIP: 'REWARDS_CLIP',
  REWARDS_CLEAR: 'REWARDS_CLEAR',

  // v3 rewards actions
  GET_REWARDS_REQUEST: 'GET_REWARDS_REQUEST',
  GET_REWARDS_SUCCESS: 'GET_REWARDS_SUCCESS',
  GET_REWARDS_ERROR: 'GET_REWARDS_ERROR',

  CLIP_REWARDS_REQUEST: 'CLIP_REWARDS_REQUEST',
  CLIP_REWARDS_SUCCESS: 'CLIP_REWARDS_SUCCESS',
  CLIP_REWARDS_ERROR: 'CLIP_REWARDS_ERROR',

  CLEAR_REWARDS: 'CLEAR_REWARDS',
  ENROLL_PROGRAM_REQUEST: 'ENROLL_PROGRAM_REQUEST',
  ENROLL_PROGRAM_SUCCESS: 'ENROLL_PROGRAM_SUCCESS',
  ENROLL_PROGRAM_ERROR: 'ENROLL_PROGRAM_ERROR',
  // end v3 rewards actions

  GET_CHARITY_REQUEST: 'GET_CHARITY_REQUEST',
  GET_CHARITY_SUCCESS: 'GET_CHARITY_SUCCESS',
  GET_CHARITY_ERROR: 'GET_CHARITY_ERROR',

  CASHBACK_GET: 'CASHBACK_GET',
  CASHBACK_BALANCE_GET: 'CASHBACK_BALANCE_GET',
  CASHBACK_PAYMENT: 'CASHBACK_PAYMENT',
  CASHBACK_CLEAR: 'CASHBACK_CLEAR',

  ACCOUNT_TYPE_GET: 'ACCOUNT_TYPE_GET',

  ACCOUNT_GET: 'ACCOUNT_GET',
  ACCOUNT_UPDATE: 'ACCOUNT_UPDATE',
  ACCOUNT_CREATE: 'ACCOUNT_CREATE',
  ACCOUNT_DELETE: 'ACCOUNT_DELETE',
  ACCOUNT_CLEAR: 'ACCOUNT_CLEAR',

  GROUP_GET: 'GROUP_GET',
  GROUP_CLEAR: 'GROUP_CLEAR',

  LANDINGPAGE_GET: 'LANDINGPAGE_GET',
  LANDINGPAGE_CLEAR: 'LANDINGPAGE_CLEAR',
  CLIP_LANDING_PAGE_OFFER: 'CLIP_LANDING_PAGE_OFFER',
  CLIP_ALL_LANDING_PAGE_OFFERS: 'CLIP_ALL_LANDING_PAGE_OFFERS',
  LANDING_PAGE_LOADER: 'LANDING_PAGE_LOADER',

  //  notification actions
  ENQUEUE_SNACKBAR: 'ENQUEUE_SNACKBAR',
  CLOSE_SNACKBAR: 'CLOSE_SNACKBAR',
  REMOVE_SNACKBAR: 'REMOVE_SNACKBAR',
  // end notification actions

  // Gallery Offers V3
  SET_GALLERY_OFFERS: 'SET_GALLERY_OFFERS',
  SET_FETCH_MORE_GALLERY: 'SET_FETCH_MORE_GALLERY',
  SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
  SET_LOADING_OFFERS: 'SET_LOADING_OFFERS',
  CLEAR_OFFERS: 'CLEAR_OFFERS',
  // Gallery Offers V3

  // Featured Offer V3
  SET_FEATURED_OFFERS: 'SET_FEATURED_OFFERS',
  // Featured Offer V3

  // WALLET Offers V3
  SET_WALLET_OFFERS: 'SET_WALLET_OFFERS',
  SET_FETCH_MORE_WALLET: 'SET_FETCH_MORE_WALLET',
  SET_WALLET_TOTAL_PAGES: 'SET_WALLET_TOTAL_PAGES',
  SET_WALLET_LOADING_OFFERS: 'SET_WALLET_LOADING_OFFERS',
  CLEAR_WALLET_OFFERS: 'CLEAR_WALLET_OFFERS',
  // WALLET Offers V3

  //
  SET_TRANSLATION: 'SET_TRANSLATION',
  //

  // Offers categories
  SET_AVAILABLE_CATEGORIES: 'SET_AVAILABLE_CATEGORIES',
  SET_CLIPPED_CATEGORIES: 'SET_CLIPPED_CATEGORIES',
  // Offers categories

  // CATEGORIES
  SET_CATEGORIES: 'SET_CATEGORIES',

  // Sort BY Option
  SET_SORTBY_GALLERY: 'SET_SORTBY_GALLERY',
  SET_SORTBY_WALLET: 'SET_SORTBY_WALLET',

  // V2 App CashBach
  SET_CASHBACK_CARD_BALANCE: 'SET_CASHBACK_CARD_BALANCE',
  SET_CASHBACK_BALANCES: 'SET_CASHBACK_BALANCES',
  SET_CASHBACK_TRANSACTIONS: 'SET_CASHBACK_TRANSACTIONS',

  // Flex Hybrid Offer
  SET_FLEX_HYBRID_OFFER: 'SET_FLEX_HYBRID_OFFER',

  // v3 Coupon History
  GET_COUPON_HISTORY_V3: 'GET_COUPON_HISTORY_V3',
  // end v3 Coupon History
};

export default constants;
